/* The following block can be included in a custom.scss */

/* make the customizations */
$theme-colors: (
  "primary": #292929,
  "secondary":red,
);

$navbar-light-color: black;
$font-size-base: 1.1rem;

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";
