@import "custom";

.App {
  text-align: center;
}

.App-Header {
  height: 76px;
}

.App-Main {
  background-color: white;
  color: #505050;
}

.App-Footer {
  background-color: #505050;
  color: white;
}
