.Services-Space {
  min-height: 15px;

}

.Services-Title {
  color: #505050;
  font-size: x-large;
}

.Services-Ilustration {
  max-width: 600px;
}

.Services-Subtitle {
  color: red;
  font-size: x-large;
}

.Services-Gray-Background {
  background-color: #efefef;
}

.Services-Gray-Background .list-group-item {
  background-color: #efefef;
}

.Services-Logos {
  max-width: 150px;
  max-height: 110px;
}

.Services-Accordion-Title > a {
  color: #505050;
}