.Contact-Space {
  min-height: 15px;
}

.Contact-Title {
  color: #505050;
  font-size: x-large;
}

.Contact-ReCaptcha {
  margin-top: 5px;
  margin-bottom: 10px;
}

.Contact-Email {
  text-align: center;
  margin-top: 10px;
}

.Contact-Email-Link {
  color: #505050;
}

.Contact-Email-Link:hover {
  color: #505050;
}

.Contact-Alert {
  margin-top: 10px;
}