.Header-Navbar {
  font-size: large;
}

.Header-Navbar-Brand-Text {
  font-size: 36px;
  color: red;
}

.Header-Navbar-Brand-Image-Container {
  position: relative;
}

.Header-Navbar-Brand-Image {
  height: 50px;
}

.Header-Navbar-Brand-Image-Text {
  color: white;
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: -webkit-center;
  line-height: 200px;
  font-size: xxx-large;
}

@media (min-width: 1200px) {
  .Header-Navbar-Brand {
    margin-left: 200px;
  }
  .Header-Navbar-Collapse {
    margin-right: 200px;
  }
}
