.Home-Image-Container {
  position: relative;
}

.Home-Image-Filter {
  position: absolute;
  height: 160px;
  width: 100%;
  background: radial-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
}

.Home-Image {
  width: 100%;
  height: 160px;
  object-fit: cover;
  filter: grayscale(100%) brightness(100%);
}

.Home-Image-Mirror {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.Home-Image-Text {
  position: absolute;
  width: 100%;
  text-align: -webkit-center;
}

.Home-Image-Text-Title {
  color: white;
  font-size: xxx-large;
  margin: 0px;
}

.Home-Image-Text-Subtitle {
  color: white;
  font-size: xx-large;
  margin: 0px;
}

.Home-Content-What-Title {
  color: red;
  font-size: x-large;
}

.Home-Content-What {
  height: auto;
}

.Home-Content-What-Image {
  max-width: 500px;
}

.Home-Content-How-Title {
  color: red;
  font-size: x-large;
  background-color: #efefef;
}

.Home-Content-How {
  background-color: #efefef;
  height: auto;
}

.Home-Content-Why-Title {
  color: red;
  font-size: x-large;
}

.Home-Content-Why {
  height: auto;
}

.Home-Content-Text {
  text-align: center;
}

.Home-Ilustration {
  max-width: 280px;
}

@media (max-width: 600px) {
  .Home-Image-Text-Title {
    font-size: 36px;
  }

  .Home-Image-Text-Subtitle {
    font-size: 28px;
  }
}

