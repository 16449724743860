.Footer-Container {
  font-size: 15px;
}

.Footer-Link {
  color: white;
}

.Footer-Link:hover {
  color: white;
}

.Cookie-Preferences {
  cursor: pointer;
  color: white;

}

.Cookie-Preferences:hover {
  color: white;
  text-decoration: underline;
}
